/**
 * @file
 * While pubwise does the heavy lifting for bidder and page configurations, we still need to pass
 * values from our bridge. They asked us to do this in a specific way.
 *
 * 1. Push bidder configurations to a pubwise.extra_bidder_params.bids array
 * 2. Each configuration should be shaped as such:
 * {
 *  bidder: 'appnexus',
 *  params: {}
 * }
 */

import * as bidderAdapters from './bidder_adapters'
import * as bridgeConstants from '../common/scripts/bridge_interface/constants'

const GPT_AND_LOCAL_STORAGE_KEY_MAPPING = [
  ['AAID', bridgeConstants.LS_BRIDGE_ANDROID_AID],
  ['bundle', bridgeConstants.LS_BRIDGE_APP_BUNDLE],
  ['country', bridgeConstants.LS_BRIDGE_COUNTRY],
  ['dnt', bridgeConstants.LS_BRIDGE_DNT],
  ['mode', bridgeConstants.LS_BRIDGE_MODE],

  // These look like duplicates but are most likely a fallback setup.
  ['mp_app_version', bridgeConstants.LS_BRIDGE_APP_VERSION_MP],
  ['mp_app_version', bridgeConstants.LS_BRIDGE_APP_VERSION_MP_INTERNAL]
]

const getBaseParams = () => {
  return GPT_AND_LOCAL_STORAGE_KEY_MAPPING.reduce((acc, curr) => {
    const bidderSettingsKey = curr[0]
    const localStorageKey = curr[1]
    const localStorageValue = localStorage.getItem(localStorageKey)

    if (localStorageValue) {
      acc[bidderSettingsKey] = localStorageValue
    }
    return acc
  }, {})
}

const BIDDERS = [
  'appnexus',
  'ix',
  'openx',
  'rubicon',
  'onemobile',
  'pubmatic',
  'onedisplay',
  'criteo',
  'adcolony'
]

// noinspection FunctionWithMoreThanThreeNegationsJS
/**
 * Using pubwise, configure header bidding for our advertisements.
 */
export const pubwiseBiddingSetup = () => {
  const baseParams = getBaseParams()
  const w = window as any

  // noinspection IfStatementWithTooManyBranchesJS
  if (!w.pubwise) {
    throw new Error('pubwiseBiddingSetup: global pubwise variable is missing')
  } else if (!w.pbjs) {
    throw new Error('pubwiseBiddingSetup: global pbjs variable is missing')
  } else if (!w.pubwise.extra_bidder_params) {
    throw new Error('pubwiseBiddingSetup: pubwise extra_bidder_params variable is missing')
  } else if (!w.pubwise.extra_bidder_params.bids) {
    throw new Error('pubwiseBiddingSetup: pubwise extra_bidder_params.bids variable is missing')
  } else if (!w.mp_globals) {
    throw new Error('pubwiseBiddingSetup: global mp_globals variable is missing')
  }

  // This sets customize params for each bidder that pubwise is then responsible for attaching to bid configs
  BIDDERS.forEach(bidder => {
    // If an adapter doesn't exist, we don't need to pass an extra params configuration object
    const bidderAdapter = bidderAdapters[bidder]
    if (bidderAdapter) {
      const params = bidderAdapter(baseParams)
      if (params) {
        const bidderConfig = {
          bidder,
          params
        }
        w.pubwise.extra_bidder_params.bids.push(bidderConfig)
      }
    }
  })

  // CRITEO USER SYNC
  // NOTE: This requires setting bidderConfig changes with pbjs. The bid params handshake process doesn't work in this case.
  // At the time of writing, this is the only way to get this into prebid without extra work in pubwise to take config
  // settings from us and combine them with their before their call to pbjs.setBidderConfig.
  // When AdOps starts needing to add bidderConfig settings through pubwise (currently they aren't which is why this is safe) we'll
  // need to work with them to formalize a data handoff approach, and they can handle the pbjs interaction.
  ;(function () {
    const mpid = localStorage.getItem(bridgeConstants.LS_BRIDGE_UUID)
    if (mpid) {
      w.pbjs.que.push(function () {
        w.pbjs.setBidderConfig({
          bidders: ['criteo'],
          config: {
            fpd: {
              user: {
                deviceid: mpid,
                deviceidtype: 'gaid'
              }
            }
          }
        })
      })
    }
  })()

  // ALL DEVICE SETTINGS MUST BE SET AT THE SAME TIME, otherwise they will overwrite each other.
  // https://digitalturbine.atlassian.net/browse/CM-1929
  // set device ifa as a global configuration so pubmatic can use it in their bid requests.
  // https://digitalturbine.atlassian.net/browse/CM-386

  // set device lat / long as a global configuration
  // so adapters can use them, specifically done for
  // ad colony integration
  ;(function () {
    const aaid = localStorage.getItem(bridgeConstants.LS_BRIDGE_ANDROID_AID)

    const device: any = {}

    if (aaid) {
      device.ifa = aaid
    }

    w.pbjs.que.push(function () {
      w.pbjs.setConfig({
        device
      })
    })
  })()

  // set iab categories as a global config
  // https://digitalturbine.atlassian.net/browse/CM-824
  ;(function () {
    const categories = window.mp_globals.ad_config.iab_category_codes

    if (categories.length) {
      w.pbjs.que.push(function () {
        w.pbjs.setConfig({
          fpd: {
            cat: categories
          }
        })
      })
    }
  })()

  w.mp_globals.pubwise_integration = w.mp_globals.pubwise_integration || {}
  w.mp_globals.pubwise_integration.setGPTAdTargeting = setGPTAdTargeting
}

// This exposes a function to allow us to set targeting directly on each gpt ad unit with the base params.
// We could inline this in each ad block (through the template), but since it relies on the same local storage and
// GPT mapping, it makes sense to generate the function here and expose it globally.
//
// Our pubwise integration library exposes a custom function that sets custom targeting values on gpt ad slots.
// We'll do a check just so we can get a warning if it's not there, but that would be bad.
// This allows us to set values like AAID and lat/long directly on the GPT slot.
const setGPTAdTargeting = gptAdSlot => {
  const baseParams = getBaseParams()

  Object.keys(baseParams).forEach(key => {
    const value = baseParams[key]
    gptAdSlot.setTargeting(key, value)
  })

  if (!baseParams['mode']) {
    gptAdSlot.setTargeting('mode', 'unknown')
  }

  const categoryCodes = window.mp_globals.ad_config.iab_category_codes
  const categoryNames = window.mp_globals.ad_config.categories

  if (categoryCodes.length) {
    gptAdSlot.setTargeting('iab_category_codes', categoryCodes)
  }

  if (categoryNames.length) {
    gptAdSlot.setTargeting('iab_category_names', categoryNames)
  }

  // Strip pathname to only filename for targeting.
  const pathnameMap = window.location.pathname.split('/')
  const filename = pathnameMap[pathnameMap.length - 1]
  const filenameWithoutExtension = filename.split('.html')[0]
  gptAdSlot.setTargeting('pathname', filenameWithoutExtension)
}
/*
;(window as any).mp_globals.pubwise_integration =
  (window as any).mp_globals.pubwise_integration || {}
;(window as any).mp_globals.pubwise_integration.setGPTAdTargeting = setGPTAdTargeting
 */
