import { NeptuneAttribute, NeptuneAttributeKey } from '../../../lib/NeptuneAttribute'
import GA4Handler from '../GA4Handler'
import {
  BlockType,
  DataLayerInteractionType,
  DataLayerNonLinkClick,
  DataLayerNonLinkClickElementAction
} from '../interfaces'
import { ga4DebugCancelLinkNavigation } from '../index'
import { findClosestAncestorNode } from '../../utilities/DOM_manipulation'

const ga4BookmarksClickHandler = (e, type, action, detail) => {
  const section = findClosestAncestorNode(e.currentTarget, '[data-mp-block-type]')
  const blockIndex = NeptuneAttribute.get(section, NeptuneAttributeKey.blockIndex)

  const data: DataLayerNonLinkClick = {
    block_position: parseInt(blockIndex),
    block_type: BlockType.bookmark,
    content_type: type,
    element: BlockType.bookmark,
    element_action: action,
    element_detail: detail,
    event: DataLayerNonLinkClickElementAction.impression_click,
    interaction_type: DataLayerInteractionType.internal
  }

  GA4Handler.pushObject('ga4BookmarksClickHandler', data)

  ga4DebugCancelLinkNavigation(e)
}

export default ga4BookmarksClickHandler
