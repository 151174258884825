class ContentBlockDataset {
  static getMpBlockIndex(element: HTMLElement) {
    return element.dataset.mpBlockIndex
  }

  static getMpBlockType(element: HTMLElement): string {
    return element.dataset.mpBlockType
  }

  static setMpBlockType(element: HTMLElement, value: string) {
    element.dataset.mpBlockType = value
  }

  static getMpContentType(element: HTMLElement) {
    return element.dataset.mpContentType
  }

  static setMpContentType(element: HTMLElement, value: string) {
    element.dataset.mpContentType = value
  }

  static getMpShareDescription(element: HTMLElement) {
    return element.dataset.mpShareDescription
  }

  static setMpShareDescription(element: HTMLElement, value: string) {
    element.dataset.mpShareDescription = value
  }

  static getMpShareTitle(element: HTMLElement) {
    return element.dataset.mpShareTitle
  }

  static setMpShareTitle(element: HTMLElement, value: string) {
    element.dataset.mpShareTitle = value
  }
}

export default ContentBlockDataset
