import FotoscapesURL from '../fotoscape/FotoscapesURL'
import { findClosestAncestorNode } from '../utilities/DOM_manipulation'
import initActionButtons from '../action_buttons/action_buttons'
import { fetchGetUrl } from '../utilities/net'

const openFullPageArticle = async (type: 'fotoscape' | 'other', id, link) => {
  // Don't open links.
  const target = event.target
  event.preventDefault()

  const parent = findClosestAncestorNode(event.target, 'article')

  let container

  if (type === 'fotoscape') {
    const parts = link.split('/')
    const fotoscapeId = parts[4]
    const url = `${FotoscapesURL.getBaseURL()}/wp/v1/lookbook/${fotoscapeId}?ckey=${FotoscapesURL.getKey()}&tf=html`

    const json = await fetchGetUrl(url)

    // Check for full article.
    if (!json.data?.lookbook?.plan?.views?.[0]?.panes?.[1]?.translations?.en) {
      ;(target as HTMLElement).click()
      return
    }

    const template = document.querySelector('#transition-article-template') as HTMLTemplateElement
    const clone = template.content.cloneNode(true) as HTMLElement

    document.getElementById('loader-full-page-article').appendChild(clone) //, template)

    container = document.querySelector('#loader-full-page-article > .transition-full')
    const article = container.querySelector('article')

    const header = container.querySelector('.block-item__header') as HTMLElement
    const title = container.querySelector('.block-item__title') as HTMLElement
    const story = container.querySelector('.block-item__body') as HTMLElement
    const timestamp = container.querySelector('.block-item__timestamp') as HTMLElement

    // Copy attributes -- needed for the share and feedback buttons.
    for (const a in parent.attributes) {
      const n = parent.attributes[a].nodeName
      if (n && n.startsWith('data')) {
        article.setAttribute(n, parent.getAttribute(n))
      }
    }

    const logoParent = parentGetLogo(parent)
    const logoClone = container.querySelector('.block-item__body-header')
    logoClone.innerHTML = logoParent.innerHTML

    timestamp.innerHTML = parent.querySelector('.block-item__timestamp').innerHTML

    title.innerHTML = json.data.lookbook.title.en
    story.innerHTML = json.data.lookbook.plan.views[0].panes[1].translations.en

    container.classList.add('active')
    container.classList.remove('inactive')

    // title.style.backgroundSize = 'cover'
    header.style.setProperty('--bg-image', `url('${json.data.lookbook.looks[0].images[0].url}')`)

    container.style.height = `${(document as any).body.clientHeight}px`
    container.style.position = 'fixed'

    // Setup feedback and share buttons.
    initActionButtons(`#loader-full-page-article > .transition-full`)

    document.addEventListener('keydown', event => {
      if (event.keyCode === 27) {
        closeViewer(container)
      }
    })

    container.querySelector('button.close').addEventListener('click', () => {
      closeViewer(container)
    })
  } else {
    container = document.getElementById(id)

    container.classList.add('active')
    container.classList.remove('inactive')
    container.style.height = `${(document as any).body.clientHeight}px`
    container.style.position = 'fixed'

    document.body.style.overflow = 'hidden'

    container.querySelector('button.close').addEventListener('click', () => {
      container.classList.remove('active')
      container.classList.add('inactive')
      document.body.removeAttribute('style')
    })
  }
}

const closeViewer = container => {
  container.classList.remove('active')
  container.classList.add('inactive')
  container.remove()
}

const parentGetLogo = (parent: HTMLElement): HTMLElement => {
  return parent.querySelector('.block-item__body-header') // Fotoscapes.
}

export default openFullPageArticle
