class Privacy {
  static dnsmiIsEnabled(v) {
    if (v === 1 || v === '1' || v === 'true' || v === true) {
      return true
    }

    return false
  }

  static disableGDPR() {
    const gdprNoticeFlag = (window as any).mp_globals?.features?.gdpr_cookie_notice_dialog

    if (!gdprNoticeFlag) return

    const platform = document.documentElement.dataset.mpPlatform
    const key = `gdpr_${platform}`
    const selection = JSON.parse(localStorage.getItem(key))
    const hasSelected = selection?.date

    if (!hasSelected || (hasSelected && selection.accepted === false)) {
      ;(window as any).mp_globals.flags.allow_gtm = false
      ;(window as any).mp_globals.flags.allow_gpt = false
      ;(window as any).mp_globals.flags.allow_pubwise = false

      document.addEventListener('DOMContentLoaded', () => {
        Array.from(document.querySelectorAll('.block--ad')).forEach(element => element.remove())
      })
    }
  }
}

export default Privacy
