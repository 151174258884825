import hash from 'object-hash'

// takes an action button and finds / parses the related content into
// an article object for bookmarks / shares
export default (contentContainer: HTMLElement) => {
  try {
    const {
      mpShareLink: link,
      mpShareTitle: title,
      mpPublisherLogo,
      mpPublisherLogoDark,
      mpShareImage: thumbnailUrl,
      mpContent: contentType,
      mpContentText: contentTypeText,
      mpPublisherName: publisherName,
      mpIsSponsored: isSponsored,
      mpShareDescription: description,
      mpPubTime: pub_time
    } = contentContainer.dataset

    const hashableData = {
      link: link.split('?')[0],
      title
    }

    const itemHash = hash(hashableData, { algorithm: 'md5' })
    const publisherLogo = mpPublisherLogo && mpPublisherLogo != 'undefined' ? mpPublisherLogo : ''
    const publisherDarkLogo =
      mpPublisherLogoDark && mpPublisherLogoDark != 'undefined' ? mpPublisherLogoDark : ''

    const data = {
      id: itemHash,
      link,
      title,
      publisherLogo,
      publisherDarkLogo,
      thumbnailUrl,
      contentType,
      contentTypeText,
      publisherName,
      isSponsored,
      description,
      pub_time
    }

    return data
  } catch (e: any) {
    console.debug('could not parse feed item', e.message)

    return null
  }
}
