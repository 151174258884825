import { setFocusToFirstFocusableElement, constrainTabFocusToModal } from './index'

/**
 * @file MP Modal
 *
 * The purpose of this module is to offer a lightweight solution for n keyboard accessible
 * modals on a single page. (Instead of one modal target, each use case can target it's own, allowing
 * for more of the templating to be done in static templates for now. Only one can show at a time
 * anyway).
 *
 * Current use cases are primarily for inline forms (such as feedback email and settings pages.)
 *
 * Future improvements may allow passing in a config with various overrides and callbacks for lifecyle
 * functions.
 * Future improvements should consider dynamically templating the modal contents.
 * Future use cases may involve holding complex javascript widgets, such as games, and
 * ad slots.
 */

// POLYFILLS
if (!Object.entries) {
  Object.entries = function (obj) {
    const ownProps = Object.keys(obj)
    let i = ownProps.length
    const resArray = new Array(i) // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}

// ######### BEGIN Modal ################

// TODO(hr): Added specific IDs to prevent incorrect closing.  There was a change to fix browser
//           translation modifying elements.  I ran translate in Chrome but wasn't able to see
//           the extra elements.  Can fix to use filtering on the data-modal-close attribute
//           if there are examples of how the markup changes. See [ #3042 ].
const CLOSE_TRIGGER_ELEMENT_IDS = [
  'modal--feedback--overlay',
  'modal--feedback--cancel_button',
  'modal--feedback--close_button',
  'modal--personalize--overlay',
  'modal--personalize--cancel_button',
  'modal--personalize--close_button'
]

/**
 * Real talk. Back button overriding.
 * ----------------------------------
 * In order to prevent the device back button from navigating away from the page when the
 * modal is open we need to manipulate the history API. This is because we cannot solve this
 * in a client-first manner that older versions don't support. Since we can't override native
 * functionality, we need to hack it. So when the device tries to navigate back on a first screen
 * it will go from hash route back to base route.
 *
 * This means that opening a modal needs to pushstate and closing a modal needs to popstate.
 * But it also means we need a listener for a hashchangeevent that checks to see if a modal is open
 * and calls its close method. Caveat, the following modal was built with some expectation that there might
 * be cause for multi-modal support. This isn't on any roadmap so we are going to solve without accounting
 * for it. That means the simplest thing to do is have a generic hash-route for modals and maintain
 * a single global reference to the active modal (only one at a time) so we know which one to close.
 *
 * (We could use the hashrouter as a messaging service if we wanted to be clever and have open triggers just be
 * internal links with some kind of uuid reference to the trigger (this would even allow for deep linking to modals)
 * but we're going to ignore all that for the nonce.)
 */

// 'Global' reference to use for closing modals from back button hashchange events
let activeModal = null
const onHashChange = () => {
  // We'll start with the simplest test - Is the modal currently supposed to be open and is there an existing modal reference?
  // Later, we may want to be cleverererer. But this let's us depend largely on the history.
  // If someone deeplinks to a modal somehow, nothing will happen, but it won't prevent the modal logic from
  // opening a new modal since we are not diffing the old and new urls from the hashchange event.
  const isModalActive = window.location.hash === '#mp-modal'
  if (!isModalActive) {
    if (activeModal) {
      activeModal.close()
    }
  }
}
window.addEventListener('hashchange', onHashChange)

class Modal {
  // We need these to be rewritable to allow external references to this modal in lifecycle callbacks
  public onShow: any
  public onClose: any
  private modal
  private modalContainer
  private activeElement // Use this property to restore correct tab position on modal close.
  private touchStart: Touch

  constructor(modalContainerId, triggers, onShow: any, onClose: any) {
    this.modal = document.getElementById(modalContainerId)
    this.modalContainer = this.modal.querySelector('.mp-modal__container')
    this.onShow = onShow
    this.onClose = onClose
    if (!this.modal) {
      // No modal container was found on the dom for these triggers.
      throw new Error(`Triggers target a modal container id, ${modalContainerId}, not on page`)
    }
    // Register external triggers
    triggers.forEach(trigger => {
      trigger.addEventListener('click', event => {
        event.stopPropagation()
        this.open(event)
      })
    })
  }

  public open = event => {
    activeModal = this
    window.location.href = '#mp-modal'
    // history.pushState({ brendan: 'poop' }, '', '#mp-modal')
    // Register internal close triggers
    this.addEventListeners()
    this.activeElement = document.activeElement
    this.modal.setAttribute('aria-hidden', 'false')
    this.modal.classList.add('active')
    this.modalContainer.style.transform = `translateY(0px)`

    // Disable scrolling on the body while the modal is active.
    document.body.style.overflow = 'hidden'
    // External Lifecycle Subscriber
    // We want to pass it the event from the original trigger as well as the Modal instance.
    this.onShow(event, this.modal)
    this.executeAfterAnimations(() => setFocusToFirstFocusableElement(this.modal))
  }

  public close = () => {
    activeModal = null
    this.modal.setAttribute('aria-hidden', 'true')
    this.executeAfterAnimations(() => this.modal.classList.remove('active'))
    this.removeEventListeners()
    // External Lifecycle Subscriber
    this.onClose(this.modal)
    // Reenable scrolling on page
    document.body.style.overflow = ''
    document.body.style.height = ''
  }

  /**
   * Any callbacks that need to be handled after an opening or closing animation
   * should be wrapped by this.
   */
  private executeAfterAnimations = cb => {
    // We want to automatically detect transitions/animations and wait for them.
    const modalStyles = (window as any).getComputedStyle(
      document.querySelector('.mp-modal__container'),
      null
    )
    const hasAnimation = modalStyles.getPropertyValue('animation-duration') !== '0s'
    const hasTransition = modalStyles.getPropertyValue('transition-duration') !== '0s'
    if (hasAnimation) {
      const handleAnimationend = () => {
        cb()
        this.modal.removeEventListener('animationend', handleAnimationend)
      }
      this.modal.addEventListener('animationend', handleAnimationend)
    } else if (hasTransition) {
      const handleTransitionend = () => {
        cb()
        this.modal.removeEventListener('transitionend', handleTransitionend)
      }
      this.modal.addEventListener('transitionend', handleTransitionend)
    } else {
      cb()
    }
  }

  private addEventListeners = () => {
    this.modal.addEventListener('touchstart', this.onTouchStart)
    this.modal.addEventListener('click', this.onClick)
    this.modalContainer.addEventListener('touchstart', this.onModalTouchStart)
    document.addEventListener('keydown', this.onKeydown)
  }

  private onModalTouchStart = (e: TouchEvent) => {
    this.modalContainer.addEventListener('touchmove', this.onModalTouchMove)
    this.modalContainer.addEventListener('touchend', this.onModalTouchEnd)
    this.touchStart = e.changedTouches[0]
  }

  private onModalTouchMove = (e: TouchEvent) => {
    const distance = e.changedTouches[0].clientY - this.touchStart.clientY
    if (distance > 0) {
      this.modalContainer.style.transform = `translateY(${distance}px)`
    }
  }

  private onModalTouchEnd = (e: TouchEvent) => {
    if (e.changedTouches[0].clientY - this.touchStart.clientY > 100) {
      this.close()
    } else {
      this.modalContainer.style.transform = `translateY(0px)`
    }
    this.modalContainer.removeEventListener('touchmove', this.onModalTouchMove)
    this.modalContainer.removeEventListener('touchend', this.onModalTouchEnd)
  }

  private removeEventListeners = () => {
    this.modal.removeEventListener('touchstart', this.onTouchStart)
    this.modal.removeEventListener('click', this.onClick)
    document.removeEventListener('keydown', this.onKeydown)
    this.modalContainer.removeEventListener('touchstart', this.onModalTouchStart)
  }

  // Filter for specific element IDs that are allowed to close the modal.  Needed due to issues
  // with browser translation feature adding child nodes to close elements.
  private onClick = event => {
    if (CLOSE_TRIGGER_ELEMENT_IDS.includes(event.target.id)) {
      history.back()
      event.preventDefault()
    }
  }

  // Filter for specific element IDs that are allowed to close the modal.  Needed due to issues
  // with browser translation feature adding child nodes to close elements.
  private onTouchStart = event => {
    if (CLOSE_TRIGGER_ELEMENT_IDS.includes(event.target.id)) {
      history.back()
      event.preventDefault()
    }
  }

  private onKeydown = event => {
    if (event.keyCode === 27) {
      history.back()
    }
    if (event.keyCode === 9) constrainTabFocusToModal(this.modal, event)
  }
}

// ########### END Modal #############

const MODAL_OPEN_TRIGGER_SELECTOR = 'data-modal-trigger-target'

const generateTriggerMap = (modalTriggers, triggerSelector) => {
  const map = modalTriggers.reduce((acc, curr) => {
    const targetModalId = curr.attributes[triggerSelector].value
    if (!targetModalId) {
      throw new Error(`Modal trigger missing modal target id value`)
    }
    if (!acc.hasOwnProperty(targetModalId)) {
      acc[targetModalId] = []
    }
    acc[targetModalId].push(curr)
    return acc
  }, {})
  return map
}

export const init = ({
  containerSelector = 'body',
  // We'll need to override the default trigger selector when we want to run the library multiple times
  // on the same container and not cause a conflict.
  triggerSelector = MODAL_OPEN_TRIGGER_SELECTOR,
  onShow = () => {},
  onClose = () => {}
} = {}) => {
  // We get the triggers first because there's no point in instantiating a modal container if nothing exists to trigger it.
  const container = document.querySelector(containerSelector)
  const modalTriggers = Array.from(container.querySelectorAll(`[${triggerSelector}]`))

  if (modalTriggers.length) {
    // Now we need to break the triggers up by which modal they point to. (Multiple triggers can
    // point at the same modal. Multiple modals can also exist.)
    const triggerMap = generateTriggerMap(modalTriggers, triggerSelector)

    // NOTE: This is for when we likely, shortly, need
    // a lingering, accessible reference to the modals instantiated. (For now, modals and triggers are bound
    // on instantiation but no other means of accessing the elements exists).
    return Object.entries(triggerMap).reduce((acc: any, [modalContainerId, triggers]: any): any => {
      const modal = new Modal(modalContainerId, triggers, onShow, onClose)
      acc[modalContainerId] = modal
      return acc
    }, {})
  }
}
