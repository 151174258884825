import { IGoogleSearchBar } from 'types-generator/platform-definition/IGoogleSearchBar'
import { neptuneDebug, neptuneWarn } from '../../../utilities'

export const userAgentIsReduced = (userAgent: string) => {
  const reductionMatch = 'Android 10; K)'

  return userAgent.toLowerCase().includes(reductionMatch.toLowerCase())
}

/**
 * Returns a matching function so that userAgentData is read only once.
 *
 * @param userAgent Full user agent string from browser.
 */
export const getMatcher = async (userAgent: string) => {
  if (!userAgentIsReduced(userAgent)) {
    neptuneDebug('getMatcher: userAgent is not reduced, falling back to old style match')
    return ua => {
      if (ua && userAgent.toLowerCase().includes(ua.toLowerCase())) {
        return true
      }

      return false
    }
  }

  neptuneDebug('getMatcher: userAgent is reduced, using getHighEntropyValues')

  const userAgentData = await getUserAgentData()

  neptuneDebug('getMatcher: userAgentData: ', userAgentData)

  if (userAgentData !== null && userAgentData.platform && userAgentData.model) {
    return ua => {
      if (ua && ua.toLowerCase() == userAgentData.model.toLowerCase()) {
        neptuneWarn(`getMatcher: FOUND MATCH: search=${ua}, match=${userAgentData.model}`)
        return true
      }

      return false
    }
  }

  throw new Error(
    `getMatcher, could not generate matcher, userAgent ${userAgent}, userAgentData: ${userAgentData}`
  )
}

const googleSearchBarAttributionMapper = async (settings: IGoogleSearchBar, userAgent: string) => {
  if (settings.attribution_map) {
    const matcher = await getMatcher(userAgent)
    // const userAgentData = await getUserAgentData()

    for (const map of settings.attribution_map) {
      for (const ua of map.matchList) {
        if (matcher(ua)) {
          return map.attribution
        }
        /*
        if (userAgentData !== null) {
          // @todo This needs to be fixed as the user agent should come in via parameter.
          if (ua.toLowerCase() == userAgentData.model.toLowerCase()) {
            neptuneDebug(
              `googleSearchBarAttributionMapper: FOUND MATCH: search: ${ua}, match ${userAgentData.model}`
            )
            return map.attribution
          }
        } else {
          // neptuneWarn('googleSearchBarAttributionMapper FALLING BACK TO OLD STYLE USER AGENT MATCH')
          if (ua.toLowerCase() == userAgent.toLowerCase()) {
            return map.attribution
          }
        }
         */
      }
    }
  }

  return settings.attribution
}

const getUserAgentData = async () => {
  try {
    const w = window as any
    const values = await w.navigator.userAgentData.getHighEntropyValues(['platform', 'model'])
    return {
      platform: values.platform,
      model: values.model
    }
  } catch (e) {
    return null
  }
}

export default googleSearchBarAttributionMapper
