export const appnexus = ({ AAID, lat, long, bundle }) => {
  const params: any = {}
  params.user = {}

  const dnsmi = localStorage.getItem('local_mp_dnsmi')
  if (dnsmi === 'true') {
    params.user.dnt = true
  }

  // xandr id sync - pass the mpid on every bid for chrome and webview
  const mpid = localStorage.getItem('mp_uuID')

  if (mpid) {
    params.user.externalUid = mpid
  }

  if (AAID && lat && long && bundle) {
    params.app = {}
    if (AAID) {
      params.app.device_id = `aaid:${AAID}`
    }

    if (lat && long) {
      params.app.geo = `lat:${lat},lng:${long}`
    }

    if (bundle) {
      // Why is this bundle 'Mozilla' in prod?
      params.app.id = bundle
    }
  }
  return params
}

export const rubicon = ({ lat, long }) => {
  // NOTE: This seems redundant. Just keeping the same dumb pattern for explicitness' sake.
  // We may need to add more values in the future and this makes it simpler.
  if (!lat && !long) {
    return null
  }

  const params: any = {}
  if (lat && long) {
    params.latLong = `${lat},${long}`
  }

  return params
}
export const onemobile = ({ AAID, lat, long, bundle }) => {
  if (!AAID && !lat && !long && !bundle) {
    return null
  }

  const params: any = {
    ext: {}
  }
  if (lat && long) {
    params.ext['req(loc)'] = `${lat},${long}`
  }
  if (AAID) {
    params.ext['d(id24)'] = AAID
  }
  if (bundle) {
    params.ext.app = bundle
  }
  return params
}

export const pubmatic = ({ lat, long }) => {
  const params: any = {}

  if (!lat && !long) {
    return null
  }

  if (lat && long) {
    params.lat = lat
    params.lon = long
  }

  return params
}

export const openx = ({ lat, long }) => {
  const params: any = {}

  if (lat && long) {
    params.customParams = {
      lat,
      long
    }
  }
  return params
}
export const adcolony = () => {
  return {}
}

/* NOTE(bb): Placeholders if we need to add custom configurations later
export const onedisplay = baseParams => {
  const params = {}
  return params
}

export const ix = baseParams => {
  const params: any = {}

  return params
}

export const criteo = baseParams => {
  const params = {}
  return params
}
*/
