import hash from 'object-hash'
import { findClosestAncestorNode } from '../../utilities/DOM_manipulation'

const likesKey = () => `${document.documentElement.dataset.mpPlatform}_likes_articles`
const dislikesKey = () => `${document.documentElement.dataset.mpPlatform}_dislikes_articles`

export const getLikes = () => {
  const likes = JSON.parse(localStorage.getItem(likesKey()))
  if (likes?.length) return likes

  return []
}

export const getDislikes = () => {
  const dislikes = JSON.parse(localStorage.getItem(dislikesKey()))
  if (dislikes?.length) return dislikes

  return []
}

const hashArticle = originalArticle => {
  const link = originalArticle.getAttribute('data-mp-share-link')
  const title = originalArticle.getAttribute('data-mp-share-title')

  const hashableData = {
    link: link.split('?')[0],
    title
  }

  return hash(hashableData, { algorithm: 'md5' })
}

const saveAction = (itemHash, getActionsFromStorage, actionKey) => {
  const actions = getActionsFromStorage()
  const updatedActions = [...actions, itemHash]

  localStorage.setItem(actionKey(), JSON.stringify(updatedActions))
}

const removeAction = (itemHash, getActionsFromStorage, actionKey) => {
  const actions = getActionsFromStorage()
  const updatedActions = actions.filter(item => item != itemHash)

  localStorage.setItem(actionKey(), JSON.stringify(updatedActions))
}

const actionTypeMap = {
  like: {
    getAction: getLikes,
    actionKey: likesKey,
    oppositeAction: 'dislike'
  },
  dislike: {
    getAction: getDislikes,
    actionKey: dislikesKey,
    oppositeAction: 'like'
  }
}

const setButtonState = (button, hashedArticle, actionType) => {
  const { getAction } = actionTypeMap[actionType]

  const savedActions = getAction()
  if (savedActions.includes(hashedArticle)) {
    button.classList.add('filled')
  } else {
    button.classList.remove('filled')
  }
}

export const handleClick = (e, actionType) => {
  const originalArticle = findClosestAncestorNode(e.currentTarget, '[data-mp-content-type]')
  const hashedArticle = hashArticle(originalArticle)

  const button = e.currentTarget
  const { getAction, actionKey, oppositeAction } = actionTypeMap[actionType]
  const savedAction = getAction()

  if (savedAction.includes(hashedArticle)) {
    removeAction(hashedArticle, getAction, actionKey)
  } else {
    saveAction(hashedArticle, getAction, actionKey)

    const { getAction: getOppositeAction, actionKey: oppositeActionKey } = actionTypeMap[
      oppositeAction
    ]

    if (getOppositeAction().includes(hashedArticle)) {
      removeAction(hashedArticle, getOppositeAction, oppositeActionKey)

      const oppositeActionButton = button.nextElementSibling || button.previousElementSibling
      setButtonState(oppositeActionButton, hashedArticle, oppositeAction)
    }
  }

  setButtonState(button, hashedArticle, actionType)
}

const setGtmEvents = (button, actionCategory, originalUrl) => {
  const url = new URL(originalUrl)

  button.setAttribute('data-gtm-event-category', actionCategory)
  button.setAttribute('data-gtm-event-action', url.origin)
  button.setAttribute('data-gtm-event-label', url.pathname)
}

// on modal show
export const setLikeDislike = (likeDislikeContainer, originalArticle) => {
  try {
    const likeButton = likeDislikeContainer.querySelector('[data-mp-button-like]')
    const likeButtonText = likeButton.querySelector('.text')
    const dislikeButton = likeDislikeContainer.querySelector('[data-mp-button-dislike]')
    const dislikeButtonText = dislikeButton.querySelector('.text')

    const originalUrl = originalArticle.getAttribute('data-gtm-share-link')

    const hashedArticle = hashArticle(originalArticle)

    setButtonState(likeButton, hashedArticle, 'like')
    setButtonState(dislikeButton, hashedArticle, 'dislike')

    const likeElements = [likeButton, likeButtonText]
    const dislikeElements = [dislikeButton, dislikeButtonText]

    likeElements.forEach(el => setGtmEvents(el, 'Like', originalUrl))
    dislikeElements.forEach(el => setGtmEvents(el, 'Dislike', originalUrl))
  } catch (e) {
    console.error('Cannot set Like Dislike on modal show', e)
  }
}
