import parseFeedItem from './parseFeedItem'
import { renderBadge } from '../../scripts/bookmarks'
import { CONTENT_TYPE_SELECTOR } from './action_buttons'
import { findClosestAncestorNode } from '../utilities/DOM_manipulation'
import ga4BookmarksClickHandler from 'content/src/assets/common/scripts/ga4_support/blocks/bookmarks'

const bookmarkKey = () => `${document.documentElement.dataset.mpPlatform}_bookmarks`

const buildBookmark = (contentContainer: HTMLElement) => {
  const bookmarkItem = {}
  const article = parseFeedItem(contentContainer)

  if (article?.id) {
    bookmarkItem[article.id] = article
  }
  return bookmarkItem
}

const saveBookmark = (e, bookmark) => {
  const bookmarks = getBookmarks()
  const key = Object.keys(bookmark)[0]
  bookmark[key].created_at = new Date().getTime()

  const updatedBookmarks = { ...bookmarks, ...bookmark }

  localStorage.setItem(bookmarkKey(), JSON.stringify(updatedBookmarks))

  ga4BookmarksClickHandler(e, 'editorial', 'saved', bookmark[key]?.link)
}

const removeBookmark = (e, bookmark) => {
  const bookmarks = getBookmarks()
  const key = Object.keys(bookmark)[0]

  delete bookmarks[key]

  localStorage.setItem(bookmarkKey(), JSON.stringify(bookmarks))

  ga4BookmarksClickHandler(e, 'editorial', 'removed', bookmark[key]?.link)
}

const handleClick = (e, bookmarkButton, contentContainer) => {
  const bookmark = buildBookmark(contentContainer)

  const key = Object.keys(bookmark)[0]
  const bookmarks = getBookmarks()

  if (bookmarks[key]) {
    removeBookmark(e, bookmark)
  } else {
    saveBookmark(e, bookmark)
  }

  renderBadge()
  setBookmarkIcon(bookmarkButton, contentContainer)
}

export const setBookmarkIcon = (bookmarkButton, contentContainer) => {
  const bookmark = buildBookmark(contentContainer)
  const key = Object.keys(bookmark)[0]
  const value: any = Object.values(bookmark)[0]

  if (!key || !value) return

  const bookmarks = getBookmarks()
  const fillSvg = bookmarkButton.querySelector('.filled')
  const fillPath = fillSvg.querySelector('path')
  const emptySvg = bookmarkButton.querySelector('.empty')
  const emptyPath = emptySvg.querySelector('path')

  if (bookmarks[key]) {
    bookmarkButton.setAttribute('data-mp-bookmarked', true)
    bookmarkButton.setAttribute('data-gtm-event-category', 'unsave')
  } else {
    bookmarkButton.setAttribute('data-mp-bookmarked', false)
    bookmarkButton.setAttribute('data-gtm-event-category', 'save')
  }

  let domain

  try {
    domain = new URL(value.link).hostname
  } catch (e) {
    domain = value.link
  }

  const elems = [bookmarkButton, fillSvg, emptySvg, fillPath, emptyPath]
  elems.map(elem => {
    elem.setAttribute('data-gtm-event-action', domain)
    elem.setAttribute('data-gtm-event-label', value.link)
  })
}

export const getBookmarks = () => {
  const bookmarks = JSON.parse(localStorage.getItem(bookmarkKey()))
  if (bookmarks) return bookmarks

  return {}
}

export const initBookmark = container => {
  const bookmarks: HTMLElement[] = Array.from(
    container.querySelectorAll('[data-mp-button-bookmark]')
  )

  bookmarks.forEach(bookmark => {
    const contentContainer = findClosestAncestorNode(bookmark, CONTENT_TYPE_SELECTOR)
    const contentType = contentContainer.getAttribute('data-mp-content-type')

    if (!contentType.includes('horoscope')) {
      setBookmarkIcon(bookmark, contentContainer)
      bookmark.addEventListener('click', e => {
        handleClick(e, bookmark, contentContainer)
      })
      bookmark.addEventListener('keyup', e => {
        // Buttons will simulate click events and we don't want duplication
        if (e.type !== 'keyup') {
          return
        }
        if (e.key !== 'Enter') {
          return
        }
        handleClick(e, bookmark, contentContainer)
      })
    }
  })
}
