import * as modal from '../utilities/modal/modal'
import { getUrlDomain } from '../utilities'
import { setBookmarkIcon } from './bookmark'
import { setLikeDislike } from './likeDislike/utils'
import {
  BlockNeptune,
  BlockType,
  DataLayerInteractionType,
  DataLayerNonLinkClickElementAction
} from 'content/src/assets/common/scripts/ga4_support/interfaces'
import ContentBlockDataset from '../../lib/ContentBlockDataset'
import ga4FeedbackModalSubmitHandler from '../ga4_support/blocks/feedback_form'
import { getContentType } from '../ga4_support'

const setTargetButtonGTMAttributes = () => {
  const threeDots: HTMLElement[] = Array.from(
    document.querySelectorAll('[data-mp-three-dots-button]')
  )

  threeDots.forEach(button => {
    const link = button.getAttribute('data-mp-share-link')

    // These have been moved to the CSS>
    // const svg = button.querySelector('svg')
    // const path = button.querySelector('path')
    // const elements = [button, svg, path]

    const elements = [button]
    elements.forEach(el => el.setAttribute('data-gtm-event-action', getUrlDomain(link)))
  })
}

export const initPersonalizationButtons = containerSelector => {
  try {
    if (
      !containerSelector ||
      document.querySelectorAll('[data-modal-custom-trigger-target-personalization]').length < 1
    ) {
      return
    }

    const modals = modal.init({
      containerSelector,
      triggerSelector: 'data-modal-custom-trigger-target-personalization'
    })

    if (!modals || !modals['modal--personalize']) {
      return
    }

    const personalizeModal = modals['modal--personalize']
    personalizeModal.onShow = (e, t) => {
      personalizeModalShowHandler(e, t, personalizeModal)
    }

    setTargetButtonGTMAttributes()

    if (window.location.hash === '#mp-modal') {
      history.pushState('', document.title, window.location.pathname + window.location.search)
    }
  } catch (e) {
    throw e
  }
}

const getOriginalArticle = (e, originalBlock) => {
  if (originalBlock.classList.value.includes('block--trending-carousel')) {
    return originalBlock
      .querySelector('.swiper-slide-active')
      .querySelector('.block-item') as HTMLElement
  } else if (originalBlock.classList.contains('block--horoscope-carousel')) {
    return originalBlock.querySelector('.swiper-slide-active') as HTMLElement
  } else {
    return e.target.closest('.block-item') as HTMLElement
  }
}

const setCopiedArticleAttributes = (
  copiedArticle: HTMLElement,
  blockContentType: string,
  shareText: string,
  teaserText: string
) => {
  ContentBlockDataset.setMpContentType(copiedArticle, blockContentType)
  ContentBlockDataset.setMpShareTitle(copiedArticle, shareText)
  ContentBlockDataset.setMpShareDescription(copiedArticle, teaserText)
}

const personalizeModalShowHandler = (e, modal, personalizeModal): any => {
  // Save a reference to the original article for use in shareButtonHandler.
  const w = window as any
  e.preventDefault()
  /* Bonkers but leave for now, having issues with Outbrain still.
  console.log(
    e.target.parentElement.parentElement.parentElement.parentElement.getAttribute(
      'data-mp-share-title'
    )
  )
  */

  if (e.target.classList.contains('icon-three-dots-swiper')) {
    const top = e.target.closest('.block__main')
    w.LAST_SHARE_ELEMENT = top.querySelector('.swiper-slide-active > article')
  } else {
    w.LAST_SHARE_ELEMENT = e.target.closest('[data-mp-content-type]')
  }

  // This is done for trending carousel block with specific three dot button position
  // The goal is to take data from the active slide that can be recognized by '-active' class only
  // const originalBlock = e.target.closest('.block') as HTMLElement
  const originalBlockTop = e.target.closest('section[data-mp-block-type]') as HTMLElement
  const originalBlock = e.target.closest('.block') as HTMLElement
  const blockContentType = ContentBlockDataset.getMpContentType(originalBlock)
  const blockBlockIndex = ContentBlockDataset.getMpBlockIndex(originalBlockTop)
  const blockBlockType = ContentBlockDataset.getMpBlockType(originalBlockTop)
  const originalArticle = getOriginalArticle(e, originalBlock)

  const copiedArticle = modal.querySelector('.block-item') as HTMLElement
  const thumbnailContainer = modal.querySelector('.block-item__thumbnail') as HTMLElement
  const titleContainer = modal.querySelector('.block-item__title') as HTMLElement
  const descriptionContainer = modal.querySelector('.block-item__description') as HTMLElement

  const bookmark = modal.querySelector('.button__icon--bookmark') as HTMLElement
  const likeDislikeContainer = modal.querySelector(
    '.block-item__actions--personalize'
  ) as HTMLElement

  const mediaContainer = modal.querySelector('.block-item__media') as HTMLElement
  const shareText = originalBlock.getAttribute('data-gtm-share-text')
  let shareTextUsed = shareText

  switch (blockBlockType) {
    case BlockType.fortune_cookie_block:
    case BlockType.quotes_widget_block:
      const teaserElementReveal = originalBlock.querySelector(
        '.reveal-widget__reveal-teaser'
      ) as HTMLElement

      let teaserElementRevealText = ''
      if (teaserElementReveal) {
        teaserElementRevealText = teaserElementReveal.innerText
      }

      titleContainer.innerHTML = shareText
      descriptionContainer.innerHTML = teaserElementRevealText
      mediaContainer.classList.add(blockContentType)

      setCopiedArticleAttributes(
        copiedArticle,
        blockContentType,
        shareText,
        teaserElementRevealText
      )

      if (likeDislikeContainer) {
        likeDislikeContainer.style.display = 'none'
      }
      if (bookmark) {
        bookmark.style.display = 'none'
      }
      break
    case BlockType.memes_widget_block:
    case BlockType.jokes_widget_block:
      titleContainer.innerHTML = originalBlock.getAttribute('data-carousel-title')
      descriptionContainer.innerHTML = originalBlock.getAttribute('data-carousel-description')
      thumbnailContainer.setAttribute('src', originalBlock.getAttribute('data-carousel-img-src'))

      shareTextUsed = originalBlock.getAttribute('data-carousel-title')

      if (likeDislikeContainer) {
        likeDislikeContainer.style.display = 'none'
      }
      break
    case BlockType.advice_of_day_block:
      likeDislikeContainer.style.display = 'none'
      titleContainer.innerHTML = originalBlock.getAttribute('data-gtm-share-text')
      descriptionContainer.innerHTML = originalBlock.getAttribute('data-advice-description')
      thumbnailContainer.setAttribute('src', originalBlock.getAttribute('data-advice-img-src'))
      break
    case BlockType.voting_block:
      likeDislikeContainer.style.display = 'none'
      titleContainer.innerHTML = originalArticle.getAttribute('data-mp-share-voting-text')
      descriptionContainer.innerHTML = 'Share where you stand!'
      thumbnailContainer.setAttribute('src', originalArticle.getAttribute('data-voting-img-src'))
      break
    case BlockType.horoscope_carousel_block:
      const theme = document.documentElement.dataset.mpAppTheme
      const horoscopeName = originalArticle.querySelector('.horoscope-name') as HTMLElement
      const horoscopeBody = originalArticle.querySelector('.horoscope-text') as HTMLElement

      titleContainer.innerHTML = horoscopeName.innerText
      descriptionContainer.innerHTML = horoscopeBody.innerText
      mediaContainer.classList.add(blockContentType)

      if (theme === 'moment') {
        const activeSlide = originalBlock.querySelector('.swiper-slide-active')
        const right = activeSlide.querySelector('.right')
        const iconUrl = getComputedStyle(right).backgroundImage.slice(5, -2)
        thumbnailContainer.setAttribute('src', iconUrl)
        thumbnailContainer.classList.add('block-item__thumbnail--horoscope')
      }

      setCopiedArticleAttributes(
        copiedArticle,
        blockContentType,
        horoscopeName.innerText,
        horoscopeBody.innerHTML
      )

      if (likeDislikeContainer) {
        likeDislikeContainer.style.display = 'none'
      }
      break
    case BlockType.horoscope_top_block:
    case BlockType.horoscope_details_group_block:
    case BlockType.horoscope_details_block:
      const data = new BlockNeptune(originalArticle)
      data.copyAttributesToElement(copiedArticle)
      const icon = originalArticle.querySelector('.block-item__header .icon')
      const iconUrl = getComputedStyle(icon).backgroundImage.slice(5, -2)
      const bodyContainer = modal.querySelector('.block-item__body') as HTMLElement

      bodyContainer.classList.add('block-item__body--horoscope')
      titleContainer.classList.add('block-item__title--horoscope')
      titleContainer.innerHTML = data.title
      descriptionContainer.innerHTML = originalArticle.querySelector('.block-item__body').innerHTML
      thumbnailContainer.setAttribute('src', iconUrl)
      thumbnailContainer.classList.add('block-item__thumbnail--horoscope')

      if (likeDislikeContainer) {
        likeDislikeContainer.style.display = 'none'
      }
      if (bookmark) {
        bookmark.style.display = 'none'
      }
      break
    case BlockType.weather_widget_block:
      const weatherTitleValueElement = originalBlock.querySelector('.tValue') as HTMLElement
      const weatherTitleLabelElement = originalBlock.querySelector('.tLabel.active') as HTMLElement

      let weatherTitleText = ''
      if (weatherTitleValueElement && weatherTitleLabelElement) {
        weatherTitleText = weatherTitleValueElement.innerText + weatherTitleLabelElement.innerText
      }

      const weatherDescriptionElement = originalBlock.querySelector('.body__place') as HTMLElement
      const weatherDescriptionElementText = weatherDescriptionElement?.innerText || ''
      const bodyIconHref = originalBlock.querySelector('.body__icon').getAttribute('src')

      titleContainer.innerHTML = weatherTitleText
      descriptionContainer.innerHTML = weatherDescriptionElementText
      mediaContainer.classList.add(blockContentType)
      mediaContainer.querySelector('img').src = bodyIconHref

      setCopiedArticleAttributes(
        copiedArticle,
        blockContentType,
        weatherTitleText,
        weatherDescriptionElementText
      )

      if (likeDislikeContainer) {
        likeDislikeContainer.style.display = 'none'
      }

      if (bookmark) {
        bookmark.style.display = 'none'
      }
      break
    default:
      // Copy dataset attributes to clone.
      const original = new BlockNeptune(originalArticle)
      original.copyAttributesToElement(copiedArticle)

      shareTextUsed = original.title

      thumbnailContainer.setAttribute('src', original.image)
      titleContainer.innerHTML = original.title
      descriptionContainer.innerHTML = original.description
      mediaContainer.className = 'block-item__media'

      if (bookmark) {
        bookmark.style.display = 'flex'
        setBookmarkIcon(bookmark, originalArticle)
        bookmark.addEventListener('click', () => {
          personalizeModal.close()
        })
      }

      if (likeDislikeContainer) {
        likeDislikeContainer.style.display = 'flex'
        setLikeDislike(likeDislikeContainer, originalArticle)
        likeDislikeContainer.addEventListener('click', function () {
          setTimeout(() => personalizeModal.close(), 800)
        })
      }
  }

  ga4FeedbackModalSubmitHandler({
    block_position: parseInt(blockBlockIndex),
    block_type: BlockType[blockBlockType],
    content_type: getContentType(BlockType[blockBlockType]),
    element: BlockType[blockBlockType],
    element_action: DataLayerNonLinkClickElementAction.click_three_dots,
    element_detail: shareTextUsed,
    event: DataLayerNonLinkClickElementAction.impression_click,
    interaction_type: DataLayerInteractionType.internal,
    domain: null,
    path: null,
    title: null
  })
}
