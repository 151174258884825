// NOTE: Magentaweb works differently than our other solutions. Instead of being passed a message from the iframe, we need to
// sniff the value of a cookie with the key 'dnt'. (This works because this is the only platform that shares a domain
// between tmo's DNS page and our content pages).
// In order to sync their approach with ours, we can simply use that 'dnt' cookie as the source of truth. Since this is
// the only platform with this behavior for the forseeable future and the cookie is such a generically identified one, we're
// going to scope the approach by platform.
// NOTE: (We could also do this by looking at the actual domain instead of the platform value since that is the only domain
// where this is set following this pattern, but that means we can't do testing on dev tiers (which are different domains)
// by aritificially setting the cookie.
import * as constants from './constants'
const cookie = require('cookie')

// Filter out sensitive values based on DNSMI/DNT/IFADTRK
/**
 * On initial load, we want to set all the dynamic user/session variables from the
 * client in one pass.
 *
 * If the user has opted out with DNSMI or DNT, we want to remove the values used
 * by third party services from local storage altogether. (Even though the lookups
 * will still be handled by us, it's a simple targeted approach).
 */
export const refreshAllDataFromClient = deviceData => {
  const platform = document.documentElement.dataset.mpPlatform.toLowerCase()

  if (platform === 'magentaweb') {
    integrateDntCookieValueWithDnsmiSolution(document.cookie)
  }

  const dnsmiOptedOut = dnsmiUserHasOptedOutViaCookie(document.cookie)
  const dntOptedOut = dntUserHasOptedOut(deviceData)

  dnsmiSaveCookieToLocalStorage(dnsmiUserHasOptedOutViaCookie(document.cookie))

  // Reset storage if opt out flags are set.  Checked on every page load.
  if (dnsmiOptedOut || dntOptedOut) {
    resetLocalStorage()
  }

  if (deviceData) {
    Object.keys(deviceData).forEach(key => {
      // Ignore null values from bridge (causing issues with IP lookup when permissions denied in client, see CM-2379 / #5218).
      if (deviceData[key] === null) {
        return
      }

      // Always save values for internal use.
      localStorage.setItem('int_mp_' + key, deviceData[key])

      if (!dnsmiOptedOut && !dntUserHasOptedOut(deviceData)) {
        localStorage.setItem('mp_' + key, deviceData[key])
      }

      if (!dnsmiOptedOut && dntOptedOut && !keyIsBlacklisted(key)) {
        // If DNT enabled, save only certain shared values, otherwise save everything.
        // TODO: Get an absolute answer from product what should and shouldn't be blacklisted in the case of dnt (not dnsmi).
        localStorage.setItem('mp_' + key, deviceData[key])
      }
    })
  } else {
    console.debug('No device data available. Setting DNT value based on navigator.')
    localStorage.setItem(constants.LS_BRIDGE_DNT, navigator.doNotTrack)
    localStorage.setItem(constants.LS_BRIDGE_IFADTRK, (!navigator.doNotTrack).toString())
  }

  // For metropcs, if dnsmi we set dnt (so it can be sent).  This came last in original version
  // so I'm guessing it's an override.
  const platforms = ['metropcs', 'metroweb', 'magentaweb']
  if (platforms.includes(platform)) {
    if (localStorage.getItem(constants.LS_BRIDGE_DNSMI_LOCAL) === 'true') {
      localStorage.setItem('mp_dnt', 'true')
      localStorage.setItem('mp_ifadtrk', 'false')
    } else {
      localStorage.setItem('mp_dnt', 'false')
      localStorage.setItem('mp_ifadtrk', 'true')
    }
  }
}

export const integrateDntCookieValueWithDnsmiSolution = cookieString => {
  const cookies = cookie.parse(cookieString)

  if (['0', '1'].includes(cookies.dnt)) {
    console.debug('Overriding mp_dnsmi with t-mobile dnt value')
    const expiryDate = new Date(Date.now() + 25 * 365 * 24 * 60 * 60 * 1000).toUTCString()

    // Create or update DNS cookie.
    document.cookie =
      'mp_dnsmi=' + cookies.dnt + '; expires=' + expiryDate + '; path=/; SameSite=None; Secure'
  }
}

const keyIsBlacklisted = k => {
  const blacklist = [
    'uuID',
    'androidID',
    'androidAID',
    'lat',
    'lng',
    'locSource',

    // TODO: Are we still tracking these? --hrivera
    'zip',
    'country',

    // TODO: Confirm what pistachio returns as well.
    'loc_lat',
    'loc_lng',
    'addr_mLatitude',
    'addr_mLongitude'
  ]

  return blacklist.includes(k)
}

/**
 * Syncs the cookie value with a localStorage value. This lets us hang the logic off
 * local storage in anticipation of eventually deprecating the cookie use altogether, but also to keep
 * our internal approaches standardized around local storage.
 */
const dnsmiSaveCookieToLocalStorage = v => {
  localStorage.setItem(constants.LS_BRIDGE_DNSMI_LOCAL, v.toString())
}

/**
 * Until we can pass back the DNS value to the client, the source of truth is the local cookie
 * value instead of the data call to the bridge.
 */
const dnsmiUserHasOptedOutViaCookie = cookieString => {
  const cookies = cookie.parse(cookieString)

  return cookies.mp_dnsmi === '1'
}

export const dntUserHasOptedOut = deviceData => {
  return deviceData['dnt'] === true || deviceData['ifadtrk'] === false
}

/**
 * We want to purge most of the preexisting mp_ and int_mp_ values in localStorage (why?).
 *
 * Dark or light theme is whitelisted to persist client app setting across page clicks.
 */
const resetLocalStorage = () => {
  const matchPersist = /(darkMode)$/
  const matchDelete = /^(mp_)/ // Removing int_mp_ to test intra page internal tracking.

  Object.keys(localStorage).forEach(key => {
    if (!matchPersist.test(key) && matchDelete.test(key)) {
      localStorage.removeItem(key)
    }
  })
}
