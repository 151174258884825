import xml2js from 'xml2js'

export interface FetchOptions {
  method: string | undefined
  url: string
  data: any | undefined
  headers: any | undefined
  body: string | Buffer | undefined
}

export const fetchWithOptions = async (cfg: FetchOptions) => {
  const { method, url, data, headers, ...rest } = cfg

  const options = {
    method: method || 'GET',
    headers: headers || {},
    ...rest
  }

  if (data) {
    options.body = JSON.stringify(data)
    options.headers['Content-Type'] = 'application/json'
  }

  const response = await fetch(url, options)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  try {
    const json = await parseResponse(response)
    return {
      data: json,
      headers: response.headers
    }
  } catch (e) {
    throw e
  }
}

export const fetchGetUrl = async aUrl => {
  return await fetchWithOptions({ url: aUrl } as FetchOptions)
}

export const fetchPostUrlWithData = async (url, data) => {
  return await fetchWithOptions({
    method: 'POST',
    url,
    data
  } as FetchOptions)
}

export const parseResponse = async response => {
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.includes('application/xml')) {
    const text = await response.text()

    try {
      const parser = new xml2js.Parser()
      return await parser.parseStringPromise(text)
    } catch (error) {
      console.error('Error parsing XML:', error)
      throw error // Or handle the error as needed
    }
  } else {
    return await response.json()
  }
}
