import * as bridgeInterface from '../common/scripts/bridge_interface/bridge_interface'

const setColorScheme = theme => {
  document.getElementsByTagName('html')[0].setAttribute('data-color-scheme', theme)
}

const setSatusDark = () => {
  const bars = document.querySelectorAll('meta.status-bar')
  bars.forEach(bar => bar.setAttribute('content', '#121212'))
}

/**
 * Initialize color scheme.
 *
 * If the client app preference exists in the bridge it is used (regardless of device settings).
 */
export const initColorScheme = () => {
  const darkMedia = window.matchMedia('(prefers-color-scheme: dark)')
  const lightMedia = window.matchMedia('(prefers-color-scheme: light)')

  darkMedia.addListener(
    e => e.matches && !bridgeInterface.isThemeConfigured() && setColorScheme('dark')
  )

  lightMedia.addListener(
    e => e.matches && !bridgeInterface.isThemeConfigured() && setColorScheme('light')
  )

  if (bridgeInterface.isThemeConfigured()) {
    if (bridgeInterface.isWebviewDarkMode()) {
      setColorScheme('dark')
      setSatusDark()
    } else {
      setColorScheme('light')
    }
  } else {
    if (darkMedia.matches) {
      setColorScheme('dark')
      setSatusDark()
    } else {
      setColorScheme('light')
    }
  }
}
