const fireworkNormalizeEventLabel = tagname => {
  switch (tagname.toLowerCase()) {
    case 'fw-embed-feed':
      return 'embed-feed'
    case 'fw-storyblock':
      return 'storyblock'
    default:
      throw new Error(`fireworkNormalizeEventLabel: tagname ${tagname} not supported`)
  }
}

export default fireworkNormalizeEventLabel
