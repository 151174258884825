export const handleTouchEvents = (selector: string) => {
  const elList = document.querySelectorAll(selector)

  Array.from(elList).forEach(el => {
    el.addEventListener('touchstart', e => {
      e.stopImmediatePropagation()
    }, true)

    el.addEventListener('touchmove', e => {
      e.stopImmediatePropagation()
    }, true)

    el.addEventListener('touchend', e => {
      e.stopImmediatePropagation()
    }, true)
  })
}
