// CCPA compliance procedures for T-Mobile properties

import Privacy from '../../lib/Privacy'

const disableGPTLoad = () => {
  const w = window as any

  w.mp_globals.flags.allow_gpt = false

  // Set a callback to clean up ad blocks that will never render.
  document.addEventListener('DOMContentLoaded', () => {
    Array.from(document.querySelectorAll('.block--ad')).forEach(element => element.remove())
    Array.from(document.querySelectorAll('.block--multiplex')).forEach(element => element.remove())
    Array.from(document.querySelectorAll('.block--sticky-ad')).forEach(element => element.remove())
  })
}

const disableBlocks = () => {
  const w = window as any

  w.mp_globals.flags.allow_pubwise = false
  w.mp_globals.flags.allow_gtm = false
  w.mp_globals.flags.allow_nativo = false
  w.mp_globals.flags.allow_playbuzzVideo = false

  document.addEventListener('DOMContentLoaded', () => {
    Array.from(document.querySelectorAll('.block--nativo')).forEach(element => element.remove())
    Array.from(document.querySelectorAll('.block--playbuzz-video')).forEach(element =>
      element.remove()
    )
  })
}

const removeBlocks = dnsmiStatus => {
  if (!Privacy.dnsmiIsEnabled(dnsmiStatus)) {
    return
  }

  disableBlocks()
  disableGPTLoad()
}

export default removeBlocks
