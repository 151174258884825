const FOCUSABLE_ELEMENTS = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])',
  'button:not([disabled]):not([aria-hidden])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])'
]

const getFocusableNodes = container => {
  const focusableNodes: any[] = Array.from(container.querySelectorAll(FOCUSABLE_ELEMENTS))
  return focusableNodes
}

const setFocusToFirstFocusableElement = container => {
  const focusableNodes = getFocusableNodes(container)
  if (focusableNodes.length) {
    focusableNodes[0].focus()
  }
}

const constrainTabFocusToModal = (container, event) => {
  const isNavigating = event.which === 9
  if (!isNavigating) return

  const focusableNodes = getFocusableNodes(container)
  const { activeElement } = document

  const firstFocusableElement = focusableNodes[0]
  const lastFocusableElement = focusableNodes[focusableNodes.length - 1]
  const isFirstElementFocused = activeElement === firstFocusableElement
  const isLastElementFocused = activeElement === lastFocusableElement
  const isNavigatingForward = !event.shiftKey

  if (isNavigatingForward && isLastElementFocused) {
    firstFocusableElement.focus()
    event.preventDefault()
  }
  if (!isNavigatingForward && isFirstElementFocused) {
    lastFocusableElement.focus()
    event.preventDefault()
  }
}

export { setFocusToFirstFocusableElement, constrainTabFocusToModal, FOCUSABLE_ELEMENTS }
