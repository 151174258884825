import { fetchPostUrlWithData } from '../utilities/net'

export default async feedItem => {
  const w = window as any

  if (!w?.mp_globals?.features?.dynamic_feed_sharing) {
    return null
  }

  const { feed_item_api } = w.mp_globals.env

  const response = await fetchPostUrlWithData(feed_item_api, feedItem)

  return response.data.id
}
