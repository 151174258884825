export interface UserAgentHighEntropyValues {
  architecture: string
  bitness: string
  brands: {
    brand: string
    version: string
  }[]
  fullVersionList: {
    brand: string
    version: string
  }[]
  mobile: true
  model: string
  platform: string
  platformVersion: string
  wow64: false
}

export class GA4UserAgent {
  architecture: string
  bitness: string
  browser: string
  browserVersion: string
  mobile: boolean
  model: string
  platform: string
  platformVersion: string
  wow64: false

  constructor(userAgent: UserAgentHighEntropyValues) {
    this.architecture = userAgent.architecture
    this.bitness = userAgent.bitness
    this.mobile = userAgent.mobile
    this.model = userAgent.model
    this.platform = userAgent.platform
    this.platformVersion = userAgent.platformVersion
    this.wow64 = userAgent.wow64

    this.setFromHintsBrowser(userAgent.fullVersionList)
  }

  setFromHintsBrowser(list) {
    // Search for Chrome and use if found.
    for (const i of list) {
      if (i.brand === 'Google Chrome') {
        this.browser = i.brand
        this.browserVersion = i.version

        return
      }
    }

    // Chrome not found, use the first one.
    if (list.length) {
      const first = list[0]
      this.browser = first.brand
      this.browserVersion = first.version
    }
  }
}
