import template from '../shared/templates/feed_item'
import initActionButtons from '../action_buttons/action_buttons'
import * as modal from '../utilities/modal/modal'
import { getBookmarks } from '../action_buttons/bookmark'
import ga4BookmarksClickHandler from 'content/src/assets/common/scripts/ga4_support/blocks/bookmarks'
import { FeedItemContext } from 'content/src/lib/feed/interfaces'

export const renderBadge = () => {
  const bookmarkBadge: HTMLElement = document.querySelector('[data-bookmarks-badge]')

  if (!bookmarkBadge) return

  const bookmarks = getBookmarks()
  const num = Object.values(bookmarks).length

  if (num === 0) {
    bookmarkBadge.textContent = ''
    bookmarkBadge.style.display = 'none'
    return
  }

  if (num <= 99) bookmarkBadge.textContent = `${num}`
  if (num > 99) bookmarkBadge.textContent = '99+'

  bookmarkBadge.style.display = 'flex'
}

const initBookmarks = () => {
  try {
    renderBadge()
    const bookmarkSection = document.querySelector('[data-bookmarks-index]')

    if (!bookmarkSection) return

    const bookmarks: FeedItemContext[] = Object.values(getBookmarks())

    if (bookmarks.length) {
      bookmarkSection.innerHTML = ''
    }

    bookmarks.reverse().forEach((bookmark: FeedItemContext) => {
      const articleHtml = template({ ...bookmark, layout: 'list-small' })

      const articleWrapper = document.createElement('div')
      articleWrapper.innerHTML = articleHtml

      // Note: you need to enable preserve log to test this.
      const link: HTMLElement = articleWrapper.querySelector('a[href]')
      link.addEventListener('click', e => {
        ga4BookmarksClickHandler(e, 'editorial', 'visited', link.getAttribute('href'))
      })

      const articleComplete = articleWrapper.querySelector('article')
      bookmarkSection.appendChild(articleComplete)
    })

    initActionButtons('[data-bookmarks-index]')
    modal.init({ containerSelector: '[data-bookmarks-index]' })
  } catch (e) {
    console.log('INIT BOOKMARKS ERROR')
  }
}

export default initBookmarks
