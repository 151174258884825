import URLSearchParams from '@ungap/url-search-params' // Polyfill for < v49
import ipLocate, { IPLocateResult } from '../IPLocate'
import { locationStoredIsValid } from '../device'
import { neptuneDebug } from '../../../utilities'
import * as constants from './constants'
import * as device from '../device'

/**
 * Returns device data from either the bridge or the querystring.
 *
 * @param bridge Passed in from global scope for now to allow testing.
 * @param queryString This defaults to location.search but passing as param allows us to test.
 *
 */
export const getDeviceData = (bridge, queryString): any => {
  // If we're in a CCT, we'll be using the searchParams as a pseudobridge dump
  const searchParams = new URLSearchParams(queryString)

  // For now, the only way we can assume we are in a cct is if mpid is a param (and if the
  // bridge is unavailable. (We can figure out what it means when both are true later (They shouldn't both be).))
  const isCCT = bridge == null && searchParams.has('mpid')

  let data = {}

  if (bridge != null) data = getDeviceDataFromBridge(bridge)
  if (isCCT) data = getDeviceDataFromQuerystring(queryString)

  // lookup location if no location is provided from bridge / QS
  locationLookup(data)

  return data
}

const getDeviceDataFromBridge = bridge => {
  let deviceData: any = {}

  const adData = bridge.getAllAdData ? bridge.getAllAdData() : null
  if (adData !== '' && adData != null) {
    deviceData = JSON.parse(adData)
  }

  if (!deviceData.uuID) {
    const deviceId = bridge.getDeviceID?.()

    deviceData.uuID = deviceId ? deviceId : bridge.getAndroidId?.()
  }

  if (!deviceData.mpAppVersion) {
    const appVer = bridge.getApplicationVersionName?.()
    deviceData.mpAppVersion = appVer
  }

  const locationData = bridge.getLocation ? bridge.getLocation(true) : null
  if (locationData !== '' && locationData != null) {
    // This stinks.
    deviceData = {
      ...deviceData,
      ...JSON.parse(locationData)
    }
  }

  deviceData.mode = 'webview'

  return deviceData
}

// NOTE: The values passed by the client through the querystring are currently a bit out of sync
// with those passed by the bridge. Both in name and availability. The job of this method is therefore
// twofold, extracting the values and also remapping them to be inline with what comes out of the bridge so that
// all other bridge interface logic still applies.
const getDeviceDataFromQuerystring = queryString => {
  const searchParams = new URLSearchParams(queryString)

  const deviceData: any = {}

  // Until we sync up the two apis, we'll manually map values. (key->value = qskey->bridgekey)
  const keyMaps = [
    ['mpid', 'uuID'],
    ['lat', 'lat'],
    ['lng', 'lng'],
    ['appVer', 'appVer'],
    ['appVersion', 'appVersion'],
    // When do these overwrite each other?
    ['mpAppVersion', 'mpAppVersion'],
    ['app_version', 'mpAppVersion'],
    ['appVersionCode', 'appVersionCode'],
    ['androidAID', 'androidAID'],
    ['darkMode', 'darkMode'],
    ['mode', 'mode'],
    ['partner', 'partner'],
    ['sb', 'sb']
  ]

  for (const keyMap of keyMaps) {
    const [qsKey, bridgeKey] = keyMap
    const value = searchParams.get(qsKey)

    // do not process data that has not been replaced in query like "[mode]" or "{mode}"
    // according to https://github.com/mobileposse/neptune/issues/2930 the client
    // could send us bad data so we need to filter this on our end
    if (!value) continue
    if (value.match(/\[[^\]]*\]/)) continue
    if (value.match(/\{[^\}]*\}/)) continue

    deviceData[bridgeKey] = value
  }

  return deviceData
}

export const locationLookup = async data => {
  if (device.doNotTrack()) {
    neptuneDebug('locationLookup: doNotTrack is enabled, location lookup ABORTED')
    return
  }

  // Leave these for now.
  // neptuneDebug('locationLookup: bridge data: ', data)
  // neptuneDebug('locationLookup: localStorage lat: ', localStorage.getItem(constants.LS_BRIDGE_LAT))
  // neptuneDebug('locationLookup: localStorage lng: ', localStorage.getItem(constants.LS_BRIDGE_LNG))

  const hasFeature = (window as any)?.mp_globals?.features?.location_lookup

  const locationMissingFromBridge = data && (!data.lat || !data.lng)
  const locationInLocalStorageValid = locationStoredIsValid()

  // Leave these for now.
  // neptuneDebug('locationLookup: hasFeature: ', hasFeature)
  // neptuneDebug('locationLookup: locationInLocalStorageValid: ', locationInLocalStorageValid)
  // neptuneDebug('locationLookup: locationMissingFromBridge: ', locationMissingFromBridge)

  if (hasFeature && locationMissingFromBridge) {
    // Location data is missing so perform a lookup, async is used here so first attempt
    // may "fail", but will be available in localStorage on second page load, otherwise we
    // have to add async/await all the way up and down the chain.
    try {
      const lookup = await ipLocate(
        parseInt(localStorage.getItem('mp_lots')),
        !locationInLocalStorageValid
      )
      if (lookup) {
        locationSave(lookup)
      }
    } catch (e) {
      console.log('locationLookup: ipLocate failed')
    }
  }
}

const locationSave = (lookup: IPLocateResult) => {
  localStorage.setItem(constants.LS_BRIDGE_LAT, lookup.latitude.toString())
  localStorage.setItem(constants.LS_BRIDGE_LNG, lookup.longitude.toString())
  localStorage.setItem(constants.LS_BRIDGE_LOCATION_TIMESTAMP, lookup.cacheTimestamp.toString())
}
