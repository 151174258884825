import GA4Handler from '../GA4Handler'
import { DataLayerNonLinkClick } from '../interfaces'

/**
 * Handles clicks on share buttons.
 */
const ga4ShareButtonClickHandler = (dataLayerObject: DataLayerNonLinkClick) => {
  GA4Handler.pushObject('ga4ShareButtonClickHandler', dataLayerObject)
}

export default ga4ShareButtonClickHandler
